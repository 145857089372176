// authStore.js
import { create } from "zustand";
import { bootstrapAppData } from "../utils/bootstrap";
import * as authClient from "../utils/auth-client";

const useAuthStore = create((set) => ({
  firstAttemptFinished: false,
  isLoggedIn: !!authClient.getToken(),
  error: null,
  isRejected: false,
  isPending: true,
  isSettled: false,

  setFirstAttemptFinished: (value) => set({ firstAttemptFinished: value }),
  setError: (value) => set({ error: value }),
  setIsRejected: (value) => set({ isRejected: value }),
  setIsPending: (value) => set({ isPending: value }),
  setIsSettled: (value) => set({ isSettled: value }),

  bootstrap: async () => {
    try {
      await bootstrapAppData();
      set({ isSettled: true, isPending: false });
    } catch (error) {
      set({ error, isRejected: true, isPending: false });
    } finally {
      set({ firstAttemptFinished: true });
    }
  },

  login: (form) => authClient.login(form).then(() => set({ isLoggedIn: true })),
  logout: () => authClient.logout().then(() => set({ isLoggedIn: false })),
}));

export default useAuthStore;
